import React, { Fragment, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Navbar/Sidebar";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Slider from "../components/Slider/Slider";
import ImageGallery from "react-image-gallery"
import { useEffect } from "react";
import { api } from "../services";
import { formatDate } from "../utils/validation";

const News = () => {
  const [newsData, setNewsData] = useState(null);
  const [firstNews, setFirstNews] = useState(null);
  const [filteredNews, setFilteredNews] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 6;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = filteredNews && filteredNews.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredNews && filteredNews.length / itemsPerPage);
  const [isSidebarOpened, setIsSidebarOpened] = useState(null);
  const [page, setPage] = useState(0);
  const [isGalleryOpened, setIsGalleryOpened] = useState(false);
  const [startIndex, setStartIndex] = useState();
  const [videoHref, setVideoHref] = useState();
  const [showVideo, setShowVideo] = useState(false)
  const [categories, setCategories] = useState([])
  const [tags, setTags] = useState([]);

  const responsive = [
    {
      itemsToShow: 1,
      maxWidth: 600,
    },
    {
      itemsToShow: 2,
      minWidth: 600,
    },
    {
      itemsToShow: 2,
      minWidth: 1200,
    },
    {
      itemsToShow: 3,
      minWidth: 1500,
    }
  ]

  const handlePageClick = (page) => {
    const newOffset = (page.selected * itemsPerPage) % newsData.length;
    setPage(page.selected);
    setItemOffset(newOffset);
  };

  const selectTag = (event, type) => {
    const elem = document.querySelector('.news-content');
    const topOffset = elem.getBoundingClientRect().top + window.scrollY;
    const tagName = event.target.innerHTML.toUpperCase();
    if (tagName === "PRIKAŽI SVE") setFilteredNews(newsData)
    else type === "tags" ? setFilteredNews(newsData.filter(item => item.tags[0].title.toUpperCase() === tagName)) : setFilteredNews(newsData.filter(item => item.categories[0].title.toUpperCase() === tagName))

    setItemOffset(0);
    setPage(0);

    if (window.innerWidth < 768) {
      window.scroll({
        behavior: 'smooth',
        top: topOffset + 900
      })
    }
    else {
      window.scroll({
        behavior: 'smooth',
        top: topOffset - 160
      })
    }
  }

  const openGallery = (event) => {
    document.querySelector('.image-gallery').classList.add('showGallery');
    setStartIndex(Number(event.target.id))
    setIsGalleryOpened(true);
  }

  const closeGallery = () => {
    document.querySelector('.image-gallery').classList.remove('showGallery');
    setIsGalleryOpened(false);
    setStartIndex(null);
  }

  const handleVideo = (event) => {
    const el = event.target;
    event.preventDefault();
    setVideoHref(el.href)
    setShowVideo(true);
  }

  useEffect(() => {
    api.news.getAllNews().then(res => {
      if (!res) return;
      setNewsData(res.slice(1));
      setFilteredNews(res.slice(1));
      setFirstNews(res[0]);
    })
    api.categories.getNewsCategories().then(res => {
      if (!res) return;
      setCategories(res);
    })
    api.categories.getNewsTags().then(res => {
      if (!res) return;
      setTags(res)
    })
  }, [])

  useEffect(() => {
    document.querySelectorAll('.news-content .middle a ').forEach(item => {
      if (!item.href.includes('youtube')) return;
      item.addEventListener('click', handleVideo)
    })
    return () => {
      document.removeEventListener("click", handleVideo);
    };
  }, [firstNews])

  useEffect(() => {
    if (!firstNews) return;
    const gallery = document.querySelector('.image-gallery-slides');
    if (!gallery) return;
    const close = document.createElement('div');
    const line1 = document.createElement('div');
    line1.classList.add('line1');
    const line2 = document.createElement('div');
    line2.classList.add('line2');
    close.classList.add('gallery-close-button');

    close.appendChild(line1);
    close.appendChild(line2);

    close.addEventListener('click', closeGallery);
    gallery.appendChild(close);
  })

  return (
    <>
      {newsData && (
        <>
          <Header setIsSidebarOpened={setIsSidebarOpened} />
          <div className="news_page">
            <img src="/images/header_images/novosti.webp" className="w-100 d-none d-md-block" alt=""></img>
            <img src="/images/header_images/novosti_mobile.webp" className="w-100 d-md-none" alt=""></img>
            <div className="news-content">
              <div className="row g-0 justify-content-center">
                <div className="col-12 col-xl-2"></div>
                <div className="col-12 col-xl-8 text-center">
                  <Link className="text-decoration-none" to={`/novosti-detalji/${firstNews.id}`} target="_blank"><h2 className='text-center text-uppercase title px-2'>{firstNews.title}</h2></Link>
                </div>
              </div>
              <div>
                <div className="row g-0 justify-content-center">
                  <div className="col-12 col-xl-2 left-side text-center order-3 order-xl-1 mt-5 mt-xl-0">
                    <div className="mx-3">
                      <div className="text-black mb-5">
                        <h5 className="mb-5 text-decoration-underline">TAGOVI</h5>
                        <div className="mx-auto side-content row justify-content-center">
                          <div className="text-md-center me-2 mt-2 side-tag row g-0 align-items-center justify-content-center" onClick={selectTag} role="button">PRIKAŽI SVE</div>
                          {tags.map((item, key) => (
                            <div className="text-center me-2 mt-2 side-tag row g-0 align-items-center justify-content-center" onClick={(e) => selectTag(e, "tags")} role="button" key={key}>{item.title.toUpperCase()}</div>
                          ))}
                        </div>
                      </div>
                      <div className="text-black mb-5 categories">
                        <h5 className="text-decoration-underline">KATEGORIJE</h5>
                        {categories.map((item, key) => (
                          <div onClick={(e) => selectTag(e, "categories")} className="mx-auto" role="button" key={key}>{item.title}</div>
                        ))}
                        <div className="pt-3 mx-auto" onClick={selectTag} role="button">Prikaži sve</div>
                      </div>
                      <div className="text-black mb-5">
                        <h5>PRATITE NAS</h5>
                        <div className="side-content mx-auto">
                          <div className="text-center row justify-content-center">
                            <div className="footer-social col-12 col-lg-8 p-0">
                              <a target="_blank" href="https://www.facebook.com/NOVOMATICrs" rel="noreferrer">
                                <i className="fa fa-facebook text-black p-2" ></i>
                              </a>
                              <a target="_blank" href="https://www.instagram.com/novomatic_americas/?hl=en" rel="noreferrer">
                                <i className="fa fa-instagram text-black p-2"></i>
                              </a>
                              <a target="_blank" href="https://twitter.com/novomatic_games?lang=en" rel="noreferrer">
                                <i className="fa fa-twitter text-black p-2"></i>
                              </a>
                              <a target="_blank" href="https://www.linkedin.com/company/novomatic/" rel="noreferrer">
                                <i className="fa fa-linkedin text-black p-2"></i>
                              </a>
                              <a target="_blank" href="https://www.youtube.com/@NOVOMATIC-AG" rel="noreferrer">
                                <i className="fa fa-youtube-play text-black p-2"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-black mb-5 popular">
                        <h5 className="mb-3 text-decoration-underline">POPULARNO</h5>
                        <Link to={`/novosti-detalji/${firstNews.id}`} className="text-decoration-none text-black">
                          <div className="mx-auto side-content">
                            <img className="w-100" src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + firstNews.cover_image_small.src}`} alt=""></img>
                            <div className="text-start">
                              <p>{firstNews.title}</p>
                              <p className="news_detail_date">{formatDate(firstNews.date)}</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-8 middle order-1 order-xl-2 mb-5 mb-xl-0">
                    <div className="px-4 px-md-5">
                      {firstNews.media && firstNews.media.length > 0 && (
                        <Slider items={3} responsiveProps={responsive}>
                          {firstNews.media.map((item, key) => {
                            return (
                              <div
                                style={{
                                  border: "12px solid transparent",
                                  textAlign: "center",
                                  boxSizing: "border-box"
                                }}
                                key={key}
                              >
                                <div className="col-6 about_item_container mb-4" style={{ width: "16.5rem" }}>
                                  <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.src}`} id={key} className="card-img-top w-100" alt="" role="button" onClick={(event, index) => openGallery(event, index)}></img>
                                </div>
                              </div>
                            )
                          })}
                        </Slider>
                      )}
                      <div className="news_detail_date  mb-3">{formatDate(firstNews.date)}</div>
                      <h5 className="mb-5 subtitle">{firstNews.subtitle}</h5>
                      <p className="text-start" dangerouslySetInnerHTML={{ __html: firstNews.description }}></p>
                      <div className="row justify-content-center d-none d-md-flex">
                        <div className="row other-news-container mb-5">
                          {currentItems.map((item, key) => (
                            <div className="col-4" key={key}>
                              <Link to={`/novosti-detalji/${item.id}`} className="text-decoration-none other-news">
                                <div className="mx-auto d-flex flex-column justify-content-between other-news-container">
                                  <div>
                                    <div className="image">
                                      {!!item.is_new && (
                                        <img src="/images/icons/new.svg" alt="" className="new_tag"></img>
                                      )}
                                      {!!item.is_coming_soon && (
                                        <img src="/images/icons/coming-soon.webp" alt="" className="new_tag p-1"></img>
                                      )}
                                      <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.cover_image_small.src}`} alt="" className="w-100"></img>
                                      {item.tags.map((tag, key) => (
                                        <Fragment key={key}>
                                          <div className="tag-container row g-0">
                                            <div className="tag px-1 me-2 mt-1 g-0">{tag.title.toUpperCase()}</div>
                                          </div>
                                        </Fragment>
                                      ))}
                                    </div>
                                    <h5>{item.title.toUpperCase()}</h5>
                                  </div>
                                  <div className="date">{formatDate(item.date)}</div>
                                </div>
                              </Link>
                            </div>
                          ))}
                          {filteredNews.length === 0 && (
                            <div className="no-data text-center">Trenutno nema novosti za odabranu kategoriju.</div>
                          )}
                        </div>
                        <ReactPaginate
                          className={`${filteredNews.length <= itemsPerPage ? "d-none" : ""}`}
                          breakLabel="..."
                          nextLabel="Next >"
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageCount}
                          forcePage={page}
                          previousLabel="< Prev"
                          renderOnZeroPageCount={null}
                          containerClassName="pagination justify-content-center"
                          activeClassName="active_page"
                          nextLinkClassName="pagination_items"
                          previousLinkClassName="pagination_items"
                          pageClassName="pagination_items"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-md-none other_news order-2 px-4 news-container">
                    <div className="mb-4">
                      {currentItems.slice(0).map((item, key) => (
                        <Link className="text-decoration-none" to={`/novosti-detalji/${item.id}`} key={key}>
                          <div className="description row g-0 mb-2 px-1">
                            {item.new && (
                              <img src="/images/icons/new.svg" alt="" className="new-icon"></img>
                            )}
                            {item.comingSoon && (
                              <img src="/images/icons/coming-soon.webp" alt="" className="coming-soon-icon"></img>
                            )}
                            <div className="col-6 image">
                              <img src={`${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.cover_image_small.src}`} className="w-100" alt=""></img>
                              {item.tags.map((tag, key) => (
                                <Fragment key={key}>
                                  <div className="tag-container row g-0">
                                    <div className="tag px-1 me-2 mt-1 g-0">{tag.title.toUpperCase()}</div>
                                  </div>
                                </Fragment>
                              ))}
                            </div>
                            <div className="col-6 row g-0 justify-content-between ps-2">
                              <div className="other_news_title title-news">{item.title.toUpperCase()}</div>
                              <div className="other_news_date row g-0 align-items-end">{formatDate(item.date)}</div>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                    {filteredNews.length === 0 && (
                      <div className="no_news text-center">Trenutno nema novosti za odabranu kategoriju.</div>
                    )}
                    <ReactPaginate
                      className={`${filteredNews.length <= itemsPerPage ? "d-none" : ""}`}
                      breakLabel="..."
                      nextLabel="Next >"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      forcePage={page}
                      previousLabel="< Prev"
                      renderOnZeroPageCount={null}
                      containerClassName="pagination justify-content-center"
                      activeClassName="active_page"
                      nextLinkClassName="pagination_items"
                      previousLinkClassName="pagination_items"
                      pageClassName="pagination_items"
                    />
                  </div>
                </div>
              </div>
            </div>
            <Sidebar
              isSidebarOpened={isSidebarOpened}
              setIsSidebarOpened={setIsSidebarOpened}
            />
            <ImageGallery
              items={(firstNews.media || []).map((item, key) => ({ ...item, original: `${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.src}`, thumbnail: `${process.env.REACT_APP_NOVOINVESTMENT_WEB_URL + item.thumbnail}` }))}
              startIndex={startIndex}
              showPlayButton={false}
              showFullscreenButton={false}
            />
            <div onClick={closeGallery} className={`${isGalleryOpened ? "fullOverlay" : ""}`}></div>
            {showVideo && (
              <div className="youtube_video">
                <iframe width="100%" height="100%" src={videoHref} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
              </div>
            )}
            <div onClick={() => setShowVideo(false)} className={`${showVideo ? "fullOverlay" : ""}`}></div>
            <Footer />
          </div>
        </>
      )}
    </>
  );
};

export default News;
