import React from "react";
import { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/Novomatic_logo.svg";
import { ReactComponent as HomeLogo } from '../../assets/Novomatic-logo-header.svg';
import Submenu from "./Submenu";
import menuItems from "../../data/NavbarData.json"

const Navbar = ({openedTab, setOpenedTab, isHomeNavbar}) => {
  const submenuRef = useRef(null);
  const location = useLocation();

  const handleSubmenu = (id, event) => {
    event.stopPropagation();
     setOpenedTab(id);
  }
  return(
    <div>
      {location.pathname !== "/" && (
        <div className="logo d-none d-md-block">
          <Link to="/">
            <HomeLogo />
          </Link>
        </div>
      )}
      {location.pathname === "/" && (
        <div className="home-logo d-none d-md-block">
          <HomeLogo/>
        </div>
      )}
      <div className="nav-bar d-none d-md-flex" style={isHomeNavbar ? {backgroundColor: "transparent", padding : "10px 0px"} : {}}>
        {menuItems.filter(i => !i.href).map(item => (
          <div key={item.id} onMouseEnter={(event) => handleSubmenu(item.id, event)} className={`${isHomeNavbar ? "text-white" : ""} nav-link with-submenu px-2 px-md-3`}>{item.title}</div>
        ))}
        {menuItems.filter(i => i.href).map(item => (
          <Link key={item.id} to={item.href}>
            <div className={`nav-link ${isHomeNavbar ? "text-white":""} px-2 px-md-3`} onMouseEnter={() => setOpenedTab(null)}>{item.title}</div>
          </Link>
        ))}
      </div>
      <Submenu ref={submenuRef} openedTab={openedTab}/>
      <div className="d-none d-md-block"></div>
    </div>
  )
}
export default Navbar;